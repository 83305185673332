<template>
  <!-- 客运凭单9.0 -->
  <div ref="passengerVoucher" class="passenger-voucher-page">
    <el-tabs type="border-card" @tab-click="tabClickFun" v-model="activeName">
      <el-tab-pane
        v-for="(item, index) in listData"
        :key="index"
        :name="item.value"
        :label="item.label"
      >
      </el-tab-pane>
      <div v-show="activeName == 'passengerVoucher'">
        <dataExplain ref="explain" :list="voucherExplainList"></dataExplain>
        <div ref="voucherForm" class="form-area">
          <el-form
            :inline="true"
            :model="voucherForm"
            label-position="left"
            label-width="96px"
            class="demo-form-inline"
          >
            <el-form-item label="结算周期：" class="code-time-range">
              <el-date-picker
                v-model="period"
                type="daterange"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :clearable="false"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="所属公司：">
              <companySelectForSearch
                ref="getGroupId1Ref"
                :searchable="true"
                @getValue="getGroupId1"
              ></companySelectForSearch>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="onSearch1"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <Table
          :table-data="tableData1"
          :titleName="titleName1"
          :col-class-type="true"
          :operation="true"
          operation-width="80"
          :table-height="tableHeight"
        >
          <template slot-scope="{ scopeRow }">
            <el-button
              v-if="listFind('凭单')"
              type="text"
              size="small"
              @click="referVoucher(scopeRow)"
              >凭单</el-button
            >
          </template>
        </Table>
        <div ref="pagination" class="pagination">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-size="voucherForm.pageSize"
            :current-page="voucherForm.currentPage"
            @size-change="onSizeChange1"
            @current-change="onCurrentChange1"
          ></el-pagination>
        </div>
      </div>
      <div
        v-show="
          ['driverDetial', 'bikeDetial', 'lineDetial'].includes(activeName)
        "
      >
        <div class="form-area">
          <el-form
            ref="detialForm"
            :inline="true"
            :model="detialForm"
            label-position="left"
            label-width="96px"
            class="demo-form-inline"
          >
            <el-form-item label="结算周期：" class="code-time-range">
              <el-date-picker
                v-model="period"
                type="daterange"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :clearable="false"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item v-if="activeName == 'bikeDetial'" label="车牌号：">
              <el-select
                v-model="detialForm.vehicleId"
                filterable
                remote
                reserve-keyword
                clearable
                placeholder="请输入车牌号"
                :remote-method="queryCph"
                @clear="detialForm.vehicleId = null"
                @focus="getCphList()"
              >
                <el-option
                  v-for="item in cphList"
                  :key="item.id"
                  :label="item.licensePlateNumber"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="activeName == 'driverDetial'"
              label="司机姓名："
            >
              <el-select
                v-model="detialForm.driverId"
                filterable
                remote
                collapse-tags
                reserve-keyword
                placeholder="请输入司机"
                clearable
                :remote-method="queryDriver"
                @clear="detialForm.driverId = null"
                @focus="getDriverList()"
              >
                <el-option
                  v-for="item in driversList"
                  :key="item.id"
                  :label="item.userName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="activeName == 'lineDetial'" label="线路名称：">
              <el-select
                v-model="detialForm.lineId"
                placeholder="请选择线路"
                clearable
                filterable
                remote
                collapse-tags
                reserve-keyword
                :remote-method="queryLine"
                @clear="detialForm.lineId = null"
                @focus="getLineList()"
              >
                <el-option
                  v-for="(item, index) in lineList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属公司：">
              <companySelectForSearch
                ref="getGroupId2Ref"
                :searchable="true"
                @getValue="getGroupId2"
              ></companySelectForSearch>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="onSearch2"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <Table
          ref="table"
          :table-data="tableData2"
          :titleName="titleName2"
          :col-class-type="true"
          :operation="true"
          operation-width="80"
          :table-height="tableHeight"
        >
          <template slot-scope="{ scopeRow }">
            <el-button type="text" size="small" @click="referDetial(scopeRow)"
              >明细</el-button
            >
          </template>
        </Table>
        <div ref="pagination" class="pagination">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-size="detialForm.pageSize"
            :current-page="detialForm.currentPage"
            @size-change="onSizeChange2"
            @current-change="onCurrentChange2"
          ></el-pagination>
        </div>
      </div>
      <div v-show="['incomeStatistics', 'incomeDetial'].includes(activeName)">
        <dataExplain ref="explain" :list="incomeExplainList"></dataExplain>
        <div class="form-area">
          <el-form
            ref="incomeForm"
            :inline="true"
            :model="incomeForm"
            label-position="left"
            label-width="96px"
            class="demo-form-inline"
          >
            <el-form-item
              :label="activeName == 'incomeDetial' ? '发车时间' : '班次时间：'"
              class="code-time-range"
            >
              <el-date-picker
                v-model="period"
                type="daterange"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                :picker-options="pickerOptions"
                end-placeholder="结束日期"
                :clearable="false"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="线路：">
              <el-select
                v-model="incomeForm.lineId"
                placeholder="请选择线路"
                clearable
                filterable
                remote
                collapse-tags
                reserve-keyword
                :remote-method="queryLine"
                @clear="incomeForm.lineId = null"
                @focus="getLineList()"
              >
                <el-option
                  v-for="(item, index) in lineList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属公司：">
              <companySelectForSearch
                ref="getGroupId3Ref"
                :searchable="true"
                @getValue="getGroupId3"
              ></companySelectForSearch>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="onSearch3"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <Table
          v-show="activeName == 'incomeStatistics'"
          :table-data="tableData3"
          :titleName="titleName3"
          :col-class-type="true"
          :table-height="tableHeight"
        />
        <Table
          v-show="activeName == 'incomeDetial'"
          :table-data="tableData4"
          :titleName="titleName4"
          :col-class-type="true"
        />
        <div ref="pagination" class="pagination">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-size="incomeForm.pageSize"
            :current-page="incomeForm.currentPage"
            @size-change="onSizeChange3"
            @current-change="onCurrentChange3"
          ></el-pagination>
        </div>
      </div>
    </el-tabs>
    <TicketPopup
      v-if="ticketShow"
      :row-data="rowData"
      @closeFun="ticketShow = false"
    />
    <DetialPopup
      v-if="detialShow"
      :header-text="headerText"
      :detial="rowDetial"
      @cancel="detialShow = false"
    ></DetialPopup>
  </div>
</template>
<script>
import dataExplain from "@/components/dataExplain/index";
import TicketPopup from "./components/ticketPopup.vue";
import DetialPopup from "./components/detialPopup.vue";
import moment from "moment";
import {
  getVehicleList,
  passengerVoucherPage,
  lineDetialPage,
  driverDetialPage,
  vehicleDetialPage,
  getDriverListAllAPI,
  getAllLine,
  incomeStatisticsPage,
  incomeDetialPage,
} from "@/api/lib/api.js";
export default {
  components: {
    dataExplain,
    TicketPopup,
    DetialPopup,
  },
  data() {
    return {
      activeName: "passengerVoucher",
      listData: [
        {
          label: "客运凭单",
          value: "passengerVoucher",
        },
        {
          label: "单车明细",
          value: "bikeDetial",
        },
        {
          label: "驾驶员明细",
          value: "driverDetial",
        },
        {
          label: "线路明细",
          value: "lineDetial",
        },
        {
          label: "其它收入统计",
          value: "incomeStatistics",
        },
        {
          label: "其它收入明细",
          value: "incomeDetial",
        },
      ],
      total: 0,
      tableHeight: 450,
      period: null,
      //客运凭单
      voucherExplainList: [
        "订单有48小时售后期，今天和昨天的数据仅供参考。数据更新频率：每30分钟。",
      ],
      voucherForm: {
        startDepartureDate: null,
        endDepartureDate: null,
        companyIds: [],
        currentPage: 1,
        pageSize: 10,
      },
      tableData1: [],
      titleName1: [
        {
          title: "班次时间",
          width: 200,
          SpecialJudgment: (res) =>
            res.startDepartureDate + " - " + res.endDepartureDate,
        },
        {
          title: "所属公司",
          props: "companyName",
          width: 250,
        },
        {
          title: "检票数",
          props: "checkTicketNum",
        },
        {
          title: "检票金额",
          props: "checkTicketAmount",
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "退检票数",
          props: "refundCheckTicketNum",
        },
        {
          title: "退检票金额",
          props: "refundCheckTicketAmount",
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "已检票售后退款数",
          width: 170,
          props: "aftermarketCheckTicketNum",
        },
        {
          title: "已检票售后退款金额",
          props: "aftermarketCheckTicketAmount",
          width: 180,
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "结算金额",
          props: "submitAmount",
          SpecialJudgment: (res) => res / 100,
        },
      ],
      ticketShow: false,
      rowData: [],
      //单车、驾驶员、线路明细
      detialForm: {
        startDepartureDate: null,
        endDepartureDate: null,
        companyIds: [],
        vehicleId: null,
        driverId: null,
        lineId: null,
        currentPage: 1,
        pageSize: 10,
      },
      cphList: [],
      driversList: [],
      lineList: [],
      tableData2: [],
      titleName2: [
        {
          title: "班次时间",
          width: 200,
          SpecialJudgment: (res) =>
            res.startDepartureDate + " - " + res.endDepartureDate,
        },
        {
          title: "车牌号",
          props: "licensePlateNumber",
        },
        {
          title: "所属公司",
          props: "companyName",
          width: 250,
        },
        {
          title: "发班数",
          props: "driveTimes",
        },
        {
          title: "票位数",
          props: "ticketNum",
        },
        {
          title: "实载率",
          props: "proportion",
          SpecialJudgment: (res) => res + "%",
        },
        {
          title: "检票数",
          props: "checkTicketNum",
        },
        {
          title: "检票金额",
          props: "checkTicketAmount",
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "退检票数",
          props: "refundCheckTicketNum",
        },
        {
          title: "退检票金额",
          props: "refundCheckTicketAmount",
          width: 100,
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "已检票售后退款数",
          width: 140,
          props: "aftermarketCheckTicketNum",
        },
        {
          title: "已检票售后退款金额",
          props: "aftermarketCheckTicketAmount",
          width: 160,
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "结算金额",
          props: "submitAmount",
          SpecialJudgment: (res) => res / 100,
        },
      ],
      detialShow: false,
      rowDetial: {},
      headerText: "",
      //其他收入
      incomeExplainList: [
        "其它收入包括：购票未乘车、退票手续费、改签手续费。订单有48小时售后期，仅统计T-2的数据（即今天和昨天的数据不统计）",
      ],
      incomeForm: {
        startDepartureDate: null,
        endDepartureDate: null,
        lineId: null,
        companyIds: [],
        currentPage: 1,
        pageSize: 10,
      },
      pickerOptions: {
        disabledDate(time) {
          let dateNum = new Date(moment().format("YYYY-MM-DD")).getTime();
          return time.getTime() > dateNum - 48 * 3600 * 1000;
        },
      },
      lineList: [],
      tableData3: [],
      tableData4: [],
      titleName3: [
        {
          title: "班次时间",
          width: 200,
          SpecialJudgment: (res) =>
            res.startDepartureDate.slice(0, 11) +
            " - " +
            res.endDepartureDate.slice(0, 11),
        },
        {
          title: "线路",
          props: "lineName",
          width: 250,
        },
        {
          title: "所属公司",
          props: "companyName",
          width: 250,
        },
        {
          title: "购票未乘车票数",
          props: "notTravelNum",
          width: 120,
        },
        {
          title: "购票未乘车票款",
          props: "notTravelAmount",
          width: 120,
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "退票数",
          props: "refundNum",
        },
        {
          title: "退票手续费",
          props: "refundServiceAmount",
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "改签票数",
          props: "changeNum",
        },
        {
          title: "改签手续费",
          props: "changeServiceAmount",
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "收入金额",
          props: "incomeAmount",
          SpecialJudgment: (res) => res / 100,
        },
      ],
      titleName4: [
        {
          title: "班次时间",
          width: 200,
          SpecialJudgment: (res) => {
            if (res.startDepartureDatetime == res.endDepartureDatetime)
              return res.endDepartureDatetime.slice(0, 16);
            else {
              return (
                res.startDepartureDatetime.slice(0, 16) +
                " - " +
                res.endDepartureDatetime.slice(10, 16)
              );
            }
          },
        },
        {
          title: "订单号",
          props: "orderId",
          width: 220,
        },
        {
          title: "票号",
          props: "childOrderId",
        },
        {
          title: "类型",
          props: "incomeType",
          SpecialJudgment: (res) => {
            return res == 1
              ? "退票手续费"
              : res == 2
              ? "改签手续费"
              : "购票未乘车";
          },
        },
        {
          title: "金额",
          props: "amount",
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "所属公司",
          props: "companyName",
          width: 250,
        },
        {
          title: "所属线路",
          props: "lineName",
          width: 250,
        },
      ],
    };
  },
  mounted() {
    this.period = this.resetTime();
    this.onSearch1();
  },
  methods: {
    resetTime() {
      const date1 =
        moment().subtract(1, "months").add(1, "days").format("YYYY-MM-DD ") +
        "00:00:00";
      const date2 = moment().format("YYYY-MM-DD ") + "23:59:59";
      return [date1, date2];
    },
    tabClickFun() {
      this.total = 0;
      this.value = null;
      this.valuename = null;
      this.$refs.getGroupId1Ref.resetForm();
      this.$refs.getGroupId2Ref.resetForm();
      this.$refs.getGroupId3Ref.resetForm();
      if (this.activeName == "passengerVoucher") {
        this.period = this.resetTime();
        this.voucherForm = {
          startDepartureDate: null,
          endDepartureDate: null,
          companyIds: [],
          currentPage: 1,
          pageSize: 10,
        };
        this.tableHeight = 450;
        this.onSearch1();
      } else if (
        ["driverDetial", "bikeDetial", "lineDetial"].includes(this.activeName)
      ) {
        this.period = this.resetTime();
        this.detialForm = {
          startDepartureDate: null,
          endDepartureDate: null,
          companyIds: [],
          vehicleId: null,
          driverId: null,
          lineId: null,
          currentPage: 1,
          pageSize: 10,
        };
        if (this.activeName == "bikeDetial") {
          this.titleName2.splice(1, 1, {
            title: "车牌号",
            props: "licensePlateNumber",
          });
        } else if (this.activeName == "driverDetial") {
          this.titleName2.splice(1, 1, {
            title: "驾驶员",
            props: "driverName",
          });
        } else {
          this.titleName2.splice(1, 1, {
            title: "线路",
            props: "lineName",
          });
        }
        this.tableHeight = 550;
        this.onSearch2();
      } else {
        const date1 =
          moment()
            .subtract(1, "months")
            .subtract(1, "day")
            .format("YYYY-MM-DD ") + "00:00:00";
        const date2 =
          moment().subtract(2, "days").format("YYYY-MM-DD ") + "23:59:59";
        this.period = [date1, date2];
        this.incomeForm = {
          endDepartureDate: null,
          startDepartureDate: null,
          lineId: null,
          companyIds: [],
          currentPage: 1,
          pageSize: 10,
        };
        this.tableHeight = 450;
        this.onSearch3();
      }
    },
    //客运凭单
    onSearch1() {
      this.voucherForm.startDepartureDate = new Date(this.period[0]).getTime();
      this.voucherForm.endDepartureDate = new Date(this.period[1]).getTime();
      passengerVoucherPage(this.voucherForm).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData1 = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    getGroupId1(v) {
      if (v == null) {
        this.voucherForm.companyIds = [];
      } else {
        this.voucherForm.companyIds = [v];
      }
    },
    referVoucher(row) {
      this.ticketShow = true;
      this.rowData = row;
    },
    onSizeChange1(size) {
      this.voucherForm.pageSize = size;
      this.voucherForm.currentPage = 1;
      this.onSearch1();
    },
    onCurrentChange1(page) {
      this.voucherForm.currentPage = page;
      this.onSearch1();
    },
    //单车、驾驶员、线路明细
    onSearch2() {
      let API = "";
      if (this.activeName == "bikeDetial") API = vehicleDetialPage;
      else if (this.activeName == "driverDetial") API = driverDetialPage;
      else API = lineDetialPage;
      this.detialForm.startDepartureDate = new Date(this.period[0]).getTime();
      this.detialForm.endDepartureDate = new Date(this.period[1]).getTime();
      API(this.detialForm).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData2 = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    getGroupId2(v) {
      if (v == null) {
        this.detialForm.companyIds = [];
      } else {
        this.detialForm.companyIds = [v];
      }
    },
    getCphList(val) {
      const data = {
        licensePlateNumber: val,
        currentPage: 1,
        pageSize: 200,
      };
      getVehicleList(data).then((res) => {
        if (res.code === "SUCCESS") {
          this.cphList = res.data.list;
        }
      });
    },
    queryCph(query) {
      this.cphList = [];
      if (query !== "") {
        setTimeout(() => {
          this.getCphList(query);
        }, 200);
      } else {
        this.getCphList();
      }
    },
    getDriverList(val) {
      const data = {
        userName: val,
        driveType: 0,
      };
      getDriverListAllAPI(data).then((res) => {
        if (res.code === "SUCCESS") {
          this.driversList = res.data;
        }
      });
    },
    queryDriver(query) {
      this.driversList = [];
      if (query !== "") {
        setTimeout(() => {
          this.getDriverList(query);
        }, 200);
      } else {
        this.getDriverList();
      }
    },
    getLineList(val) {
      getAllLine().then((res) => {
        if (res.code === "SUCCESS") {
          if (val) this.lineList = res.data.filter((i) => i.name.includes(val));
          else this.lineList = res.data;
        }
      });
    },
    queryLine(query) {
      this.lineList = [];
      if (query !== "") {
        setTimeout(() => {
          this.getLineList(query);
        }, 200);
      } else {
        this.getLineList();
      }
    },
    referDetial(row) {
      if (this.activeName == "bikeDetial") this.headerText = "单车明细";
      else if (this.activeName == "driverDetial")
        this.headerText = "驾驶员明细";
      else this.headerText = "线路明细";
      this.detialShow = true;
      this.rowDetial = row;
    },
    onSizeChange2(size) {
      this.detialForm.pageSize = size;
      this.detialForm.currentPage = 1;
      this.onSearch2();
    },
    onCurrentChange2(page) {
      this.detialForm.currentPage = page;
      this.onSearch2();
    },
    //其他收入
    onSearch3() {
      let API = "";
      if (this.activeName == "incomeStatistics") API = incomeStatisticsPage;
      else API = incomeDetialPage;
      this.incomeForm.startDepartureDate = new Date(this.period[0]).getTime();
      this.incomeForm.endDepartureDate = new Date(this.period[1]).getTime();
      API(this.incomeForm).then((res) => {
        if (res.code == "SUCCESS") {
          if (this.activeName == "incomeStatistics")
            this.tableData3 = res.data.list;
          else this.tableData4 = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    getGroupId3(v) {
      if (v == null) {
        this.incomeForm.companyIds = [];
      } else {
        this.incomeForm.companyIds = [v];
      }
    },
    onSizeChange3(size) {
      this.incomeForm.pageSize = size;
      this.incomeForm.currentPage = 1;
      this.onSearch3();
    },
    onCurrentChange3(page) {
      this.incomeForm.currentPage = page;
      this.onSearch3();
    },
  },
  destroyed() {},
};
</script>
<style lang="scss" scoped>
.passenger-voucher-page {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  .el-tabs {
    height: 100%;
    .el-tab-pane {
      height: 100%;
    }
  }
  .code-time-range {
    .el-date-editor {
      width: 13vw !important;
      ::v-deep .el-range-separator {
        line-height: 24px;
      }
    }
  }
  .Table {
    margin-top: 16px;
  }
}
</style>
