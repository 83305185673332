<template>
  <!-- 售票结算弹窗确认单 -->
  <div class="ticketPopup">
    <div class="ticketPopup-box">
      <div class="header">
        <div class="text">客运凭单</div>
        <div class="close" @click="closeFun()"></div>
      </div>
      <div id="content" ref="content" class="row-data">
        <div class="content">
          <div class="td td1">客运凭单</div>
          <div class="td">
            <div class="tdd">运输公司：{{ form.companyName }}</div>
            <div style="padding-left: 20px">
              结算周期：{{ form.startDepartureDateTime + " - " + form.endDepartureDateTime, }}
            </div>
          </div>
          <div class="td td2">线路：{{ lines.lineNames[0] }}</div>
          <Table
            :table-data="tableData"
            :resizable="false"
            :title-name="titleName"
          ></Table>
          <div class="td td3">{{ lines.mchName }}</div>
        </div>
      </div>
      <div class="footer">
        <el-button @click="closeFun()">取消</el-button>
        <el-button type="primary" @click="downloadPdf">下载</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import DomToImage from "dom-to-image";
import jspdf from "jspdf";
import host from "@/api/static/host";
import { voucherTableAPI } from "@/api/lib/api.js";
export default {
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      channelNo: host.channelNo,
      tableData: [],
      titleName: [
        {
          title: "检票数",
          props: "checkTicketNum",
        },
        {
          title: "检票金额",
          props: "checkTicketAmount",
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "退检票数",
          props: "refundCheckTicketNum",
        },
        {
          title: "退检票金额",
          props: "refundCheckTicketAmount",
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "已检票售后退款数",
          props: "aftermarketCheckTicketNum",
          width: 170,
        },
        {
          title: "已检票售后退款金额",
          props: "aftermarketCheckTicketAmount",
          width: 180,
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "结算金额",
          props: "submitAmount",
          SpecialJudgment: (res) => res / 100,
        },
      ],
      form: {},
      lines: {},
    };
  },
  mounted() {
    this.queryData();
  },
  methods: {
    queryData() {
      const data = {
        startDate: this.rowData.startDepartureDate,
        endDate: this.rowData.endDepartureDate,
        companyId: this.rowData.lineCompanyId,
        mchId: this.rowData.mchId,
      };
      voucherTableAPI(data).then((res) => {
        if (res.code == "SUCCESS") {
          this.form = res.data;
          this.lines = res.data.items[0];
        }
      });
    },
    // 生成pdf
    downloadPdf() {
      let target = this.$refs.content;
      DomToImage.toPng(document.getElementById("content"), {
        quality: 1.0,
      }).then(function (dataUrl) {
        var contentWidth = target.offsetWidth;
        var contentHeight = target.offsetHeight;
        const imgWidth = 595.28;
        const imgHeight = (595.28 * contentHeight) / contentWidth;
        const PDF = new jspdf("l", "pt", [imgWidth, imgHeight]);
        PDF.addImage(dataUrl, "JPEG", 0, 0, imgWidth, imgHeight);
        PDF.save("客运凭单" + ".pdf");
      });
    },
    // 关闭弹窗方法
    closeFun() {
      this.$emit("closeFun");
    },
  },
  created() {
    this.tableData.push(this.rowData);
  },
};
</script>

<style lang="scss" scoped>
.ticketPopup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 777;
  display: flex;
  justify-content: center;
  align-items: center;
  .ticketPopup-box {
    width: 900px;
    max-height: 650px;
    background: #ffffff;
    border-radius: 6px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .header {
      padding: 18px 24px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ebeef5;
      font-size: 15px;
      .close {
        cursor: pointer;
        width: 12px;
        height: 12px;
        background: url("../../../../assets/images/home/close.png") no-repeat;
        background-size: 100%;
      }
    }
    .row-data {
      flex: 1;
      overflow: auto;
      width: 100%;
      box-sizing: border-box;
      padding: 20px;
      font-size: 15px;
      .content {
        margin-bottom: 20px;
        .td {
          border: 1px solid #ebeef5;
          border-top: none;
          display: flex;
          align-items: center;
          padding: 0 20px;
          height: 60px;
          .tdd {
            border-right: 1px solid #ebeef5;
            height: 100%;
            padding-right: 20px;
            line-height: 60px;
          }
        }
        .td1 {
          border-top: 1px solid #ebeef5;
          justify-content: center;
        }
        .td2 {
          border-bottom: none;
        }
        .td3 {
          height: 100px;
          justify-content: flex-end;
        }
      }
    }
    .footer {
      border-top: 1px solid #ebeef5;
      padding: 10px 24px 18px 24px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
