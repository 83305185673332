<template>
  <charter-popup
    :header-text="headerText"
    max-height="700px"
    width="1000"
    @closePopupFun="$emit('cancel')"
    @confirmFun="$emit('cancel')"
  >
    <div class="content">
      <div class="form">
        <div v-if="headerText == '单车明细'" class="item">
          车牌号：{{ detial.licensePlateNumber }}
        </div>
        <div v-if="headerText == '驾驶员明细'" class="item">
          驾驶员：{{ detial.driverName }}
        </div>
        <div v-if="headerText == '线路明细'" class="item">
          线路：{{ detial.lineName }}
        </div>
        <div class="item">
          发车时间：{{
            detial.startDepartureDate + " - " + detial.endDepartureDate
          }}
        </div>
        <div class="item">实载率：{{ detial.proportion }}%</div>
        <div class="item">结算金额：{{ detial.submitAmount / 100 }}元</div>
      </div>
      <Table
        :table-data="tableData"
        :title-name="titleName"
        table-height="460px"
      ></Table>
    </div>
  </charter-popup>
</template>

<script>
import { queryDetialPage } from "@/api/lib/api.js";
export default {
  props: {
    detial: {
      type: Object,
      default: () => {},
    },
    headerText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tableData: [],
      titleName: [
        {
          title: "班次时间",
          width: 200,
          SpecialJudgment: (res) => {
            if (res.startDepartureDateTime == res.endDepartureDateTime)
              return res.startDepartureDateTime.slice(0, 16);
            else {
              return (
                res.startDepartureDateTime.slice(0, 16) +
                " - " +
                res.endDepartureDateTime.slice(10, 16)
              );
            }
          },
        },
        {
          title: "车次时间",
          props: "travelTime",
          width: 140,
          SpecialJudgment: (res) => res.slice(0, 16),
        },
        {
          title: "车牌号",
          props: "licensePlateNumber",
          width: 90,
        },
        {
          title: "驾驶员",
          props: "driverName",
        },
        {
          title: "线路",
          props: "lineName",
          width: 200,
        },
        {
          title: "票位数",
          props: "ticketNum",
        },
        {
          title: "实载率",
          props: "proportion",
          SpecialJudgment: (res) => res + "%",
        },
        {
          title: "检票数",
          props: "checkTicketNum",
        },
        {
          title: "检票金额",
          props: "checkTicketAmount",
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "退检票数",
          props: "refundCheckTicketNum",
        },
        {
          title: "退检票金额",
          props: "refundCheckTicketAmount",
          width: 100,
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "已检票售后退款数",
          width: 140,
          props: "aftermarketCheckTicketNum",
        },
        {
          title: "已检票售后退款金额",
          props: "aftermarketCheckTicketAmount",
          width: 160,
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "结算金额",
          props: "submitAmount",
          SpecialJudgment: (res) => res / 100,
        },
      ],
    };
  },
  mounted() {
    this.queryData();
  },
  methods: {
    queryData() {
      const data = {
        pageSize: 10000,
        currentPage: 1,
        companyIds: [this.detial.companyId],
        startDepartureDate: this.detial.startDepartureDate,
        endDepartureDate: this.detial.endDepartureDate,
        vehicleId: this.detial.vehicleId,
        driverId: this.detial.driverId,
        lineId: this.detial.lineId,
      };
      queryDetialPage(data).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0 20px 20px 20px;
  .form {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    font-size: 15px;
  }
}
</style>
