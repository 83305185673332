var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "charter-popup",
    {
      attrs: {
        "header-text": _vm.headerText,
        "max-height": "700px",
        width: "1000",
      },
      on: {
        closePopupFun: function ($event) {
          return _vm.$emit("cancel")
        },
        confirmFun: function ($event) {
          return _vm.$emit("cancel")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "form" }, [
            _vm.headerText == "单车明细"
              ? _c("div", { staticClass: "item" }, [
                  _vm._v(
                    " 车牌号：" + _vm._s(_vm.detial.licensePlateNumber) + " "
                  ),
                ])
              : _vm._e(),
            _vm.headerText == "驾驶员明细"
              ? _c("div", { staticClass: "item" }, [
                  _vm._v(" 驾驶员：" + _vm._s(_vm.detial.driverName) + " "),
                ])
              : _vm._e(),
            _vm.headerText == "线路明细"
              ? _c("div", { staticClass: "item" }, [
                  _vm._v(" 线路：" + _vm._s(_vm.detial.lineName) + " "),
                ])
              : _vm._e(),
            _c("div", { staticClass: "item" }, [
              _vm._v(
                " 发车时间：" +
                  _vm._s(
                    _vm.detial.startDepartureDate +
                      " - " +
                      _vm.detial.endDepartureDate
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _vm._v("实载率：" + _vm._s(_vm.detial.proportion) + "%"),
            ]),
            _c("div", { staticClass: "item" }, [
              _vm._v(
                "结算金额：" + _vm._s(_vm.detial.submitAmount / 100) + "元"
              ),
            ]),
          ]),
          _c("Table", {
            attrs: {
              "table-data": _vm.tableData,
              "title-name": _vm.titleName,
              "table-height": "460px",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }