import { render, staticRenderFns } from "./detialPopup.vue?vue&type=template&id=637060aa&scoped=true"
import script from "./detialPopup.vue?vue&type=script&lang=js"
export * from "./detialPopup.vue?vue&type=script&lang=js"
import style0 from "./detialPopup.vue?vue&type=style&index=0&id=637060aa&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "637060aa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/1416652-cci-50406239-448444/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('637060aa')) {
      api.createRecord('637060aa', component.options)
    } else {
      api.reload('637060aa', component.options)
    }
    module.hot.accept("./detialPopup.vue?vue&type=template&id=637060aa&scoped=true", function () {
      api.rerender('637060aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projectTravel/financialManagement/components/detialPopup.vue"
export default component.exports