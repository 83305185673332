var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ticketPopup" }, [
    _c("div", { staticClass: "ticketPopup-box" }, [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "text" }, [_vm._v("客运凭单")]),
        _c("div", {
          staticClass: "close",
          on: {
            click: function ($event) {
              return _vm.closeFun()
            },
          },
        }),
      ]),
      _c(
        "div",
        { ref: "content", staticClass: "row-data", attrs: { id: "content" } },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("div", { staticClass: "td td1" }, [_vm._v("客运凭单")]),
              _c("div", { staticClass: "td" }, [
                _c("div", { staticClass: "tdd" }, [
                  _vm._v("运输公司：" + _vm._s(_vm.form.companyName)),
                ]),
                _c("div", { staticStyle: { "padding-left": "20px" } }, [
                  _vm._v(
                    " 结算周期：" +
                      _vm._s(
                        _vm.form.startDepartureDateTime +
                          " - " +
                          _vm.form.endDepartureDateTime
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "td td2" }, [
                _vm._v("线路：" + _vm._s(_vm.lines.lineNames[0])),
              ]),
              _c("Table", {
                attrs: {
                  "table-data": _vm.tableData,
                  resizable: false,
                  "title-name": _vm.titleName,
                },
              }),
              _c("div", { staticClass: "td td3" }, [
                _vm._v(_vm._s(_vm.lines.mchName)),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.closeFun()
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.downloadPdf } },
            [_vm._v("下载")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }